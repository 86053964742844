import { blockScroll, unBlockScroll } from '~/composables/blockScroll'
export const mobileNav = defineStore('mobileNav', {
    state: () => ({
        isOpen: false
    }),
    actions: {
        toggle () {
            this.isOpen = !this.isOpen
            this.isOpen ? blockScroll(window.pageYOffset) : unBlockScroll()
        },
        close () {
            if (this.isOpen) {
                this.isOpen = false
                unBlockScroll()
            }
        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(mobileNav, import.meta.hot))
}
